export default class Logs {
  constructor () {
    if (this.constructor === Logs) {
      throw new Error('AbstractEntity is an abstract class and cannot be instantiated directly.')
    }
    this.runtimeConfig = useRuntimeConfig()
  }

  async create () {
    throw new Error("Method 'create()' must be implemented in the subclass.")
  }

  read () {
    throw new Error("Method 'read()' must be implemented in the subclass.")
  }
}

import Logs from './logs'

export default class LogUserActivity extends Logs {
  constructor () {
    super()
    this.typeID = 2 // 2 for user activity
  }

  async create (subType = '', createdBy = '', triggerLocation = '', metadata = { created_at: '', mode: '', version: '' }) {
    metadata.created_at = new Date().toISOString()
    metadata.mode = this.runtimeConfig.public.mode
    metadata.version = this.runtimeConfig.public.version
    try {
      await $fetch('/api/ver/1.0/logs/log',
        {
          method: 'POST',
          body: {
            log_types_id: this.typeID,
            sub_type: subType,
            created_by: createdBy,
            trigger_location: triggerLocation,
            metadata
          }
        })
    } catch (error: any) {
      throw createError({
        statusCode: 500,
        statusMessage: `${error.message}`
      })
    }
  }
}
